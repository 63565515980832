'use strict';

var app = angular.module('FSS', [
    'ui.router',
    'ui.bootstrap',
    'ngMessages',
    'ngCookies',
    'ngSanitize',
    'infinite-scroll'
    // 'fullPage.js',
]);

app
        .config(['$urlMatcherFactoryProvider', '$locationProvider', '$stateProvider', '$urlRouterProvider', '$httpProvider', '$qProvider', '$compileProvider',
            function ($urlMatcherFactoryProvider, $locationProvider, $stateProvider, $urlRouterProvider, $httpProvider, $qProvider, $compileProvider) {

        // disable debug
        $compileProvider.debugInfoEnabled(false);
        // disable comments
        $compileProvider.commentDirectivesEnabled(false);
        $compileProvider.cssClassDirectivesEnabled(false);

        $qProvider.errorOnUnhandledRejections(false);
        $urlMatcherFactoryProvider.strictMode(false);

        // $urlRouterProvider.otherwise(function ($injector, $location) {
        //     // $location.url('/q/home');
        //     return window.location.href = '/q/home';
        // });

        //this below was original
        //$locationProvider.html5Mode(true);


        $locationProvider.html5Mode({
            enabled: true,
            requireBase: false
        });

        /*
         * ----------------------------------------------------------------------------------
         * # PUBLIC ROUTES
         * ----------------------------------------------------------------------------------
         */

        $stateProvider

            // HTTP 403 FORBIDDEN
            .state('403', {
                url: '/403',
                views: {
                    '': {
                        templateUrl: 'layouts/home_form.html'
                    },
                    'nav@403': {
                        templateUrl: 'front/partials/navigation.html',
                        controller: 'AuthCtrl',
                        controllerAs: 'auth'
                    },
                    'body@403': {
                        templateUrl: '403.html'
                    },
                    // 'footer@403': {
                    //     templateUrl: 'front/partials/footer.html'
                    // }
                }
            })

            // HOME-PAGE ROUTES
            // .state('home', {
            //     url: '/',
            //     views: {
            //         '': {
            //             templateUrl: 'layouts/home.html',
            //             controller: 'PublicCtrl',
            //             controllerAs: 'pubctl',
            //         },
            //         'nav@home': {
            //             templateUrl: 'front/partials/navigation.html',
            //             controller: 'AuthCtrl',
            //             controllerAs: 'auth'
            //         },
            //         'body@home': {
            //             templateUrl: 'front/home/body.html'
            //         },
            //         // 'footer@home': {
            //         //     templateUrl: 'front/partials/footer.html'
            //         // },
            //     }
            // })
            // ABOUT-PAGE ROUTES
            // .state('about', {
            //     url: '/about',
            //     views: {
            //         '': {
            //             templateUrl: 'layouts/home.html',
            //             controller: 'PublicCtrl',
            //             controllerAs: 'pubctl',
            //         },
            //          'nav@about': {
            //             templateUrl: 'front/partials/navigation.html',
            //             controller: 'AuthCtrl',
            //             controllerAs: 'auth'
            //         },
            //         'body@about': {
            //             templateUrl: 'front/home/about.html'
            //         },
            //         // 'footer@about': {
            //         //     templateUrl: 'front/partials/footer.html'
            //         // },
            //     }
            // })
            // WHY USE FITOUT BUILDER
            // .state('why_use_fitout_builder', {
            //     url: '/why-use-fitout-builder',
            //     views: {
            //         '': {
            //             templateUrl: 'layouts/home.html',
            //             controller: 'PublicCtrl',
            //             controllerAs: 'pubctl',
            //         },
            //          'nav@why_use_fitout_builder': {
            //             templateUrl: 'front/partials/navigation.html',
            //             controller: 'AuthCtrl',
            //             controllerAs: 'auth'
            //         },
            //         'body@why_use_fitout_builder': {
            //             templateUrl: 'front/home/why-use-fitout-builder.html'
            //         },
            //         // 'footer@why_use_fitout_builder': {
            //         //     templateUrl: 'front/partials/footer.html'
            //         // }
            //     }
            // })
            // DIRECTORY ROUTES
            // .state('directory', {
            //     url: '/directory',
            //     views: {
            //         '': {
            //             templateUrl: 'layouts/home.html'
            //         },
            //         'nav@directory': {
            //             templateUrl: 'front/partials/navigation.html',
            //             controller: 'AuthCtrl',
            //             controllerAs: 'auth'
            //         },
            //         'body@directory': {
            //             templateUrl: 'front/directory/index.html',
            //             controller: 'PublicCtrl',
            //             controllerAs: 'pubctl',
            //         },
            //         // 'footer@directory': {
            //         //     templateUrl: 'front/partials/footer.html'
            //         // },
            //     }
            // })
            // LEGAL ROUTES
            // .state('legal', {
            //     url: '/legal/:page',
            //     views: {
            //         '': {
            //             templateUrl: 'layouts/home.html',
            //             controller: 'PublicCtrl',
            //             controllerAs: 'pubctl',
            //         },
            //          'nav@legal': {
            //             templateUrl: 'front/partials/navigation.html',
            //             controller: 'AuthCtrl',
            //             controllerAs: 'auth'
            //         },
            //         'body@legal': {
            //             templateUrl: function ($transition$) {
            //                 switch ($transition$.page) {
            //                     case "terms":
            //                         return 'front/legal/terms.html'
            //                         break;
            //                     case "privacy":
            //                         return 'front/legal/privacy.html'
            //                         break;
            //                     // default:
            //                     //     return 'back/project/client-details.html';
            //                 }
            //             }
            //         },
            //         // 'footer@legal': {
            //         //     templateUrl: 'front/partials/footer.html'
            //         // }
            //     }
            // })
            // HELP/FAQ-PAGE ROUTES
            // .state('help', {
            //     url: '/help?topic',
            //     params: {
            //         topic: {value: 'intro'}
            //     },
            //     views: {
            //         '': {
            //             templateUrl: 'layouts/home.html',
            //             controller: 'PublicCtrl',
            //             controllerAs: 'pubctl',
            //         },
            //          'nav@help': {
            //             templateUrl: 'front/partials/navigation.html',
            //             controller: 'AuthCtrl',
            //             controllerAs: 'auth'
            //         },
            //         'body@help': {
            //             templateUrl: 'front/help/help.html'
            //         },
            //         // 'footer@help': {
            //         //     templateUrl: 'front/partials/footer.html'
            //         // },
            //         'body-details@help': {
            //             templateUrl: function ($transition$) {
            //                 switch ($transition$.topic) {
            //                     case "intro":
            //                         return 'front/help/partials/intro.html'
            //                         break;
            //                     case "account-faq":
            //                         return 'front/help/partials/account-faq.html'
            //                         break;
            //                     case "policy-faq":
            //                         return 'front/help/partials/policy-faq.html'
            //                         break;
            //                     case "privacy-faq":
            //                         return 'front/help/partials/privacy-faq.html'
            //                         break;
            //                     case "getting-started":
            //                         return 'front/help/partials/getting-started.html'
            //                         break;
            //                     case "feedback":
            //                         return 'front/help/partials/feedback.html'
            //                         break;
            //                     default:
            //                         return 'front/help/partials/intro.html';
            //                         break;
            //                 }
            //             }
            //         }
            //     }
            // })
            // SOON ROUTES
            .state('soon', {
                url: '/soon',
                views: {
                    '': {
                        templateUrl: 'layouts/home.html',
                        controller: 'PublicCtrl',
                        controllerAs: 'soon',
                    },
                     'nav@soon': {
                        templateUrl: 'front/partials/navigation.html',
                        controller: 'AuthCtrl',
                        controllerAs: 'auth'
                    },
                    'body@soon': {
                        templateUrl: 'front/home/tba.html'
                    },
                    // 'footer@soon': {
                    //     templateUrl: 'front/partials/footer.html'
                    // },
                }
            })
            // NOTIFICATIONS
            .state('notify', {
                url: '/notify/{key}/{value}?uri',
                params: {
                    key:{value:null},
                    value:{value:null},
                    uri:{value:null}
                },
                onEnter: ['$rootScope', '$state', '$transition$', '$location', '$window', function($rootScope, $state, $transition$, $location, $window) {
                    var notifyValue = {
                        type: '',
                        message: ''
                    };
                    var redirectTo = '/q/home';
                    var allowedNotificationTypes = ['success', 'error', 'info'];

                    // check if notification type is supported in views/notification/notification.html
                    if (allowedNotificationTypes.indexOf($transition$.params().key) > -1 && $transition$.params().value) {
                        notifyValue.type = $transition$.params().key;
                        notifyValue.message = $transition$.params().value;
                    }

                    // // handle custom reidrectTo
                    if ($transition$.params().uri) {
                        // replace all '-' with '/'
                        redirectTo = '/' + $transition$.params().uri.replace(/-/g, "/");
                        // redirectTo = $transition$.params().uri;
                    }

                    $rootScope.addNotification(notifyValue);
                    return $window.location.href = redirectTo;
                    // $location.url(redirectTo);
                }]
            })
            // VALIDATE ACCOUNT BY EMAIL
            .state('validateAccount', {
                url: '/validate-account/:uuid',
                params: {
                    uuid: {value: null}
                },
                controller: function ($rootScope, $state, $transition$, $location, $window, AuthService) {

                    if(!$transition$.params().uuid) {
                        $rootScope.addNotification({
                            type: 'error',
                            message: 'Invalid request'
                        });
                        $location.url('/');
                    }
                    else {
                        AuthService.__validateAccount({uuid: $transition$.params().uuid}, (err, _payload) => {
                            if(err) {
                                $rootScope.addNotification({
                                    type: 'error',
                                    message: err
                                });
                                $location.url('/');
                            }
                            else {
                                AuthService.SetCredentials(_payload);
                                if($rootScope.globals && $rootScope.globals.currentUser) {

                                    if(_payload.redirectUrl) {
                                        return $window.location.href = _payload.redirectUrl;
                                    } else {
                                        return $window.location.href = $rootScope.globals.currentUser.baseUrl;
                                    }
                                } else {
                                    return $window.location.href = "/login";
                                }
                            }
                        });
                    }
                }
            })
            // BUILDER ROUTES
            // .state('showBuilder', {
            //     url: '/builder/:id/show',
            //     views: {
            //         '': {
            //             templateUrl: 'layouts/home.html'
            //         },
            //         'nav@showBuilder': {
            //             templateUrl: 'front/partials/navigation.html',
            //             controller: 'AuthCtrl',
            //             controllerAs: 'auth'
            //         },
            //         'body@showBuilder': {
            //             templateUrl: 'front/directory/show.html',
            //             controller: 'PublicCtrl',
            //             controllerAs: 'pubctl',
            //         },
            //         // 'footer@showBuilder': {
            //         //     templateUrl: 'front/partials/footer.html'
            //         // },

            //         // '': {
            //         //     controller: function ($state, $transition$, $location, $scope, $rootScope, SearchService) {
            //         //         if (!$transition$.params().id) {
            //         //             $location.url('/');
            //         //         } else {
            //         //             SearchService.getBuilderBy('id', $transition$.params().id, (response) => {
            //         //                 if (response && response.status == false) {
            //         //                     $location.url('/');
            //         //                 } else {
            //         //                     $scope.builder = response;
            //         //                 }
            //         //             });
            //         //         }
            //         //     },
            //         //     templateUrl: 'layouts/home.html'
            //         // },
            //         // 'nav@showBuilder': {
            //         //     templateUrl: 'front/partials/navigation.html',
            //         //     controller: 'NavigationCtrl',
            //         //     controllerAs: 'navctl'
            //         // },
            //         'notifications@showBuilder': {
            //             templateUrl: 'front/partials/notifications.html'
            //         },
            //         // 'body@showBuilder': {
            //         //     templateUrl: 'front/builder/show.html'
            //         // },
            //         // 'footer@showBuilder': {
            //         //     templateUrl: 'front/partials/footer.html'
            //         // },
            //     }
            // })
            // REGISTER ROUTES
            .state('password_reset', {
                url: '/reset?password_reset',
                params: {
                    password_reset: {value: null, notify: false}
                },
                views: {
                    '': {
                        templateUrl: 'layouts/home_form.html',
                        controller: 'AuthCtrl',
                        controllerAs: 'auth'
                    },
                    'nav@password_reset': {
                        templateUrl: 'front/partials/navigation.html'
                    },
                    'body@password_reset': {
                        templateUrl: 'front/auth/password-reset.html'
                    },
                    // 'footer@password_reset': {
                    //     templateUrl: 'front/partials/footer.html',
                    //     controller: 'PublicCtrl',
                    //     controllerAs: 'pubctl'
                    // },
                }
            })
            // REGISTER ROUTES
            .state('login_standalone', {
                url: '/login?email&url&reviewscopeofwork',
                params: {
                    email: { value: null, notify: false },
                    url: { value: null, notify: false },
                    reviewscopeofwork: { value: null, notify: false }
                },
                views: {
                    '': {
                        templateUrl: 'layouts/home_form.html',
                        controller: 'AuthCtrl',
                        controllerAs: 'auth',
                    },
                    'nav@login_standalone': {
                        templateUrl: 'front/partials/navigation.html'
                    },
                    'body@login_standalone': {
                        templateUrl: 'front/auth/login.html',
                    },
                    // 'footer@login_standalone': {
                    //     templateUrl: 'front/partials/footer.html'
                    // },
                }
            })
            // REGISTER LANDING PAGE
            .state('register_landing', {
                url: '/register?type&step&info&project_uuid&uuid',
                params: {
                    type: {value: null, dynamic: true},
                    step: {value: '0'},
                    info: {value: null},
                    project_uuid: {value: null},
                    uuid: {value: null}, //uuid from user
                },
                views: {
                    '': {
                        templateUrl: 'layouts/home_form.html',
                        controller: 'RegisterCtrl',
                        controllerAs: 'registerctl',
                    },
                    'nav@register_landing': {
                        templateUrl: 'front/partials/navigation.html',
                        controller: 'AuthCtrl',
                        controllerAs: 'auth'
                    },
                    'body@register_landing': {
                        templateUrl: 'front/auth/register-landing.html'
                    },
                    // 'footer@register_landing': {
                    //     templateUrl: 'front/partials/footer.html',
                    //     controller: 'PublicCtrl',
                    //     controllerAs: 'pubctl'
                    // },
                }
            })
            .state('register_standalone_params', {
                url: '/register/:uuid/?redirectUrl&project_uuid&email&invitation_accept',
                params: {
                    uuid: {value: null},
                    redirectUrl: {value: null},
                    project_uuid: {value: null},
                    email: {value: null},
                    invitation_accept: {value: null}
                },
                views: {
                    '': {
                        templateUrl: 'layouts/home_form.html',
                        controller: 'RegisterCtrl',
                        controllerAs: 'registerctl'
                    },
                    'nav@register_standalone_params': {
                        templateUrl: 'front/partials/navigation.html',
                        controller: 'AuthCtrl',
                        controllerAs: 'auth'
                    },
                    'body@register_standalone_params': {
                        templateUrl: 'front/auth/register.html',
                    },
                    // 'footer@register_standalone_params': {
                    //     templateUrl: 'front/partials/footer.html'
                    // }
                },
                resolve: {
                    anyKeyName: ['$rootScope', '$state', '$transition$', '$location', '$q', 'AuthService',
                                function($rootScope, $state, $transition$, $location, $q, AuthService) {

                        var deferred = $q.defer();
                        if(!$transition$.params().project_uuid && $transition$.params().email && $transition$.params().uuid) {
                            var data = {
                                uuid: $transition$.params().uuid,
                                email: $transition$.params().email
                            };

                            // decline invitation
                            if($transition$.params().invitation_accept == 0) {
                                AuthService.__declineInvitation(data, (err, response) => {
                                    if(err) {
                                        $rootScope.addNotification({
                                            type: 'error',
                                            message: err
                                        });
                                        deferred.resolve( $location.url('/') );
                                        // return $location.url('/');
                                    }
                                    else {
                                        $rootScope.addNotification({
                                            type: 'info',
                                            message: 'Invitation declined'
                                        });
                                        // return $location.url('/');
                                        deferred.resolve( $location.url('/') );
                                    }
                                });
                            }

                            // accept invitation
                            if($transition$.params().invitation_accept == 1) {
                                AuthService.__acceptInvitation(data, (err, response) => {
                                    if(err) {
                                        $rootScope.addNotification({
                                            type: 'error',
                                            message: err
                                        });
                                        // return $location.url('/');
                                        deferred.resolve( $location.url('/') );
                                    }
                                    else {
                                        if(response) {
                                            $rootScope.addNotification({
                                                type: 'info',
                                                message: 'Invitation accepted'
                                            });
                                            if(response.existingClient) {
                                                // return $location.url('/login?email='+data.email);
                                                deferred.resolve( $location.url('/login?email='+data.email) );
                                            } else {
                                                deferred.resolve();
                                            }
                                        }
                                    }
                                });
                            }
                        }
                        else {
                            deferred.resolve();
                        }
                        return deferred.promise;
                    }]
                }
            });


        // HTTP INTERCEPTORS
        $httpProvider.interceptors.push(['$rootScope', '$q', '$location', '$injector', '$window',function ($rootScope, $q, $location, $injector, $window) {
            return {
                'responseError': function (response) {
                    var uibModal = $injector.get('$uibModal');
                    if (!response){
                        return $q.reject();
                    }
                    if (response.status === 500) {
                        return $q.reject(response);
                    }
                    // commented for development purposes
                    if (response.status === 401) {
                        // var AuthService = $injector.get('AuthService');
                        // AuthService.ClearCredentials();
                        if ($rootScope.currentUser && !$rootScope.currentUser.confirmed && $rootScope.currentUser.profileCompleted ){
                            $window.location.href="/register?info=confirmation_required";
                        } else {
                            if (response.data){
                                if(response.data.currentUser && !response.data.currentUser.confirmed) {
                                    $window.location.href="/register?info=confirmation_required";
                                } else {
                                    var msg = response.data;
                                    if(msg.error) {
                                        msg = msg.error;
                                    }
                                    $rootScope.addNotification({
                                        type: 'error',
                                        message: msg
                                    });
                                }
                            }
                        }
                        return $q.resolve(response);
                    }
                    var AuthService = $injector.get('AuthService');

                    if (response.status === 403) {
                        AuthService.ClearCredentials();
                    //     // $injector.get('$state').go('login');
                    //     if (!AuthService.error403){
                    //         AuthService.error403 = true;
                    //         var modalInstance = uibModal.open({
                    //             animation: true,
                    //             ariaLabelledBy: 'modal-title',
                    //             ariaDescribedBy: 'modal-body',
                    //             component: 'loginComponent',
                    //             size: 'md',
                    //             resolve: {
                    //                 redirectToUrl: () => {
                    //                     return null;
                    //                 },
                    //                 redirectToBase: () => {
                    //                     return true;
                    //                 }
                    //             },
                    //             windowClass: 'login-no-background',
                    //         });


                    //         modalInstance.result.then(() => {
                    //             AuthService.error403 = false;
                    //             return $q.resolve(response);
                    //         }, (_response) => {
                    //             AuthService.error403 = false;
                    //             return $q.resolve(response);
                    //         });
                    //     } else {
                    //         return $q.resolve(response);
                    //     }

                    }


                }
            };
        }]);
    // ------------- END $httpProvider.interceptors ------------

    }])
    .run(['$rootScope', '$state', 'AuthService', '$transitions', '$q', '$timeout', 'ConfigService',
    function ($rootScope, $state,  AuthService,   $transitions,   $q,   $timeout,   ConfigService) {

            var allowDebug = false;
            $rootScope.log = (...args) => {
                if (!allowDebug) {
                    return;
                }
                let aRegexResult = (new Error().stack).split('\n');
                aRegexResult[2] = aRegexResult[2].replace("    at ", "");
                console.info("!!!DEBUG: ", aRegexResult[2], ":", ...args);
            }
            $rootScope.checkMethod = (method) => {
                if ($rootScope.globals && $rootScope.globals.availableMethods){
                    var foundIndex = $rootScope.globals.availableMethods.indexOf(method);
                    return foundIndex;
                } else {
                    return -1;
                }
            }
            // METHOD TO CHECK IF LOGGED IN USER IS SPECIFIC ROLE TYPE
            $rootScope.amI = (id) => {
                if(id && $rootScope.globals && $rootScope.globals.currentUser) {
                    if(Array.isArray(id)) {
                        return id.indexOf($rootScope.globals.currentUser.AccountTypeId) > -1;
                    } else {
                        return $rootScope.globals.currentUser.AccountTypeId == +id;
                    }
                }
                else {
                    return false;
                }
            }
            $rootScope.companyOwner = () => {
                if(!$rootScope.globals || !$rootScope.globals.currentUser) {
                    return false;
                }
                return $rootScope.globals.currentUser.id == $rootScope.globals.currentUser.companyId;
            }
            // METHOD TO HANDLE TOAST NOTIFICATIONS
            $rootScope.toast_notifications = [];
            $rootScope.addNotification = (notification) => {
                if(!notification) {
                    return;
                }
                // add the notification
                $rootScope.toast_notifications.unshift(notification);
                // remove it after 5 seconds
                $timeout(() => {
                    $rootScope.toast_notifications.pop();
                }, 5000);
            };
            // 1.x LAYOUT
            $transitions.onStart({ }, function(trans) {
                var AuthService = trans.injector().get('AuthService');
                AuthService.__initializeGlobals();
            });

        }
    ]);


/*
 * RESOLVE METHODS
 */

// CHECK WHETHER CURRENT USER IS LOGGED IN
function isLoggedIn($rootScope, $q, $state, $timeout, AuthService) {

    var deferred = $q.defer();

    $timeout(function () {
        if (!AuthService.getLoggedUser()) {
            // user is not logged, do not proceed
            $rootScope.addNotification({
                type: 'error',
                message: 'The requested location requires successful login on the system. Please login and try again!'
            });
            $state.go('home');
            deferred.reject(new Error("User is not logged in"));
        } else {
            // everything is fine, proceed
            deferred.resolve("User logged in");
        }
    }, 100);

    return deferred.promise;

}


// CHECK WHETHER CRRENTLY LOGGED IN USER HAS SPECIFIC ROLE
function hasRole($window, $rootScope, $q, $state, $timeout, AuthService) {
    // check whether user is logged
    isLoggedIn($rootScope, $q, $state, $timeout, AuthService).then(function () {
            // get defined route rapp/views/back/calendar/create.htmloles
            AuthService.getRouteRoles($state.current.url, function (response) {
                if (response && Array.isArray(response)) {
                    // check whether the logged in user has appropriate role for the route
                    if (response.indexOf(AuthService.myProfile.type) > -1) {
                        return true;
                    } else {
                        // access denied, redirect the user silently to homepage or static 403 forbidden page
                        $window.location.href = '/403';
                    }
                } else {
                    return false;
                }
            });
        })
        .catch(function (err) {
            if (err) {  }
            $rootScope.addNotification({
                type: 'error',
                message: 'The requested location requires successful login on the system. Please login and try again!'
            });
            $window.location.href = '/403';
        })
}